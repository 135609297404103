import type { SpotReportData } from 'types/spot';
import type { UserFavorites } from 'types/userFavorites';

export const getRegionsBadgesFromFavorites = ({
  favorites,
  all,
}: {
  favorites: UserFavorites | null;
  all: boolean;
}): Array<string> => {
  const regions = favorites?.map((fav: SpotReportData) => fav.subregionName);
  return [...(all ? ['All'] : []), ...new Set(regions)] as Array<string>;
};

export const filterFavoritesByRegions = (
  favorites: UserFavorites | undefined,
  selectedRegions: Array<string>,
): UserFavorites | undefined => {
  if (selectedRegions.includes('all')) return favorites;
  return favorites?.filter((fav: SpotReportData) => selectedRegions.includes(fav.subregionId));
};
