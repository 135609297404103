import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { getUserFavorites } from '@surfline/web-common';

import { useAppSelector } from 'stores/hooks';
import type { UserFavorites } from 'types/userFavorites';

type Region = { id: string; name: string };

const useFavorites = ({ allRegions = false }: { allRegions?: boolean }) => {
  const favorites = useAppSelector(getUserFavorites, shallowEqual) as UserFavorites;
  const regions: Array<Region> = useMemo(
    () =>
      favorites?.reduce<Array<Region>>((acc, fav) => {
        if (!acc.some((region) => region.id === fav.subregionId)) {
          acc.push({ id: fav.subregionId, name: fav.subregionName });
        }
        return acc;
      }, []),
    [favorites],
  );

  return {
    favorites,
    regions: allRegions ? [{ id: 'all', name: 'All' }, ...regions] : regions,
  };
};

export default useFavorites;
